// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { callPrivateApi } from '@/api/apiHelper'
import { UserDB, UserNotification, UsersDBSearchParams } from '@/enitites/user/model/types'

const USER_HANDLER = '/user'
export const userApi = {
  async getUser(userId: number) {
    return callPrivateApi<UserDB>('get', `${USER_HANDLER}/${userId}`)
  },

  async updateUser(user: UserDB) {
    return callPrivateApi<boolean>('post', USER_HANDLER, user)
  },

  async getCurrentUser() {
    return callPrivateApi<UserDB>('get', USER_HANDLER)
  },

  async getPublicUsers({
    pageNumber: p,
    pageSize: pp,
    orgIds: orgs,
    sortBy: sb,
    keyword: k,
    exclude: ex,
  }: UsersDBSearchParams) {
    return callPrivateApi<UserDB[]>('get', '/users', {
      params: {
        p,
        pp,
        orgs,
        sb,
        k,
        ex,
      },
    })
  },

  async readNotification(id: number) {
    return callPrivateApi('post', `/notifications/${id}`)
  },

  async getNotifications() {
    return callPrivateApi<UserNotification[]>('get', '/notifications')
  },

  async addOtherProfile(user: UserDB) {
    return callPrivateApi<UserDB>('post', '/user/profiles', user)
  },

  async getUsersCount() {
    return callPrivateApi<number>('get', '/users/count')
  },

  async resetPassword(email: string) {
    const data = callPrivateApi('post', `${USER_HANDLER}/reset-password`, email, {
      headers: { 'Content-Type': 'text/plain' },
    })
    return { data }
  },

  async magicLink(email: string) {
    const data = callPrivateApi('post', `${USER_HANDLER}/magic-link`, email, {
      headers: { 'Content-Type': 'text/plain' },
    })
    return { data }
  },

  async setProfileAsMain(userId: number) {
    const data = callPrivateApi<UserDB>('put', `${USER_HANDLER}/${userId}/set-main`)
    return { data }
  },

  async releaseProfile(profileInfo: { id: number; password: string; email: string }) {
    const data = callPrivateApi<UserDB>('put', `user/profiles`, profileInfo)
    return { data }
  },
}
